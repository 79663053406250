import GuestLayout from '@/Layouts/GuestLayout'
import { Head, useForm, usePage } from '@inertiajs/react'
import OAuthButton from '@/Components/OAuthButton.jsx'
import { Button } from 'primereact/button'
import React, { useEffect, useState } from 'react'
import { InputText } from 'primereact/inputtext'
import { InputOtp } from 'primereact/inputotp'
import { ProgressSpinner } from 'primereact/progressspinner'
import { getCookie } from '@/lib/utils.js'
import { FormattedMessage, useIntl } from 'react-intl'

export default function Login({ users }) {
    const intl = useIntl()
    const { props } = usePage()
    const [useOTP, setUseOTP] = useState(false)
    const [loginlinkSent, setLoginlinkSent] = useState(false)
    const [processing, setProcessing] = useState(false)
    const [processingPin, setProcessingPin] = useState(false)
    const [pinSuccess, setPinSuccess] = useState(false)
    const [error, setError] = useState('')
    const [uuid, setUuid] = useState('')
    const [token, setToken] = useState('')
    const [welcome, setWelcome] = useState('')

    const { data, setData } = useForm({ email: '' })

    const launchMagic = () => {
        const toSubmit = {
            ...data,
        }
        setProcessing(true)
        axios.post('/auth/magic', { data: toSubmit })
            .then(response => {
                setProcessing(false)
                setLoginlinkSent(false)
                if (response.data.error) {
                    setError(response.data.error)
                }
                if (response.data.sent) {
                    setToken('')
                    setLoginlinkSent(response.data.number_end)
                    setUuid(response.data.uuid)
                }
            })
    }

    useEffect(() => {
        if (getCookie('fauna_user_firstname')) {
            setWelcome(getCookie('fauna_user_firstname'))
        }
    }, [])

    useEffect(() => {
        if (token.length === 6) {
            setError('')
            setProcessingPin(true)
            axios.get('/auth/pin/' + uuid + '/' + token)
                .then(response => {
                    if (response.data.error) {
                        setProcessingPin(false)
                        setError(response.data.error)
                    }
                    if (response.data.logged_in) {
                        setPinSuccess(true)
                        // Session cookie set, simply reload the page
                        location.reload()
                    }
                })
        }

    }, [token])

    return (
        <GuestLayout topbar={false}>
            <Head title='Log in' />
            <div className='flex justify-content-center login-card'>
                <div className='card bg-white mb-1 p-6 text-center sm:w-8 sm:p-4 lg:w-24rem'>
                    <img
                        src='/images/logo/logo_splash.svg'
                        height={130}
                        className='mb-0' />
                    {<div><small>{welcome && <><FormattedMessage id={'auth.welcome back'} />, {welcome}</>}</small>
                    </div>}
                    <div className={'flex flex-column align-items-center mt-4'}>
                        <OAuthButton
                            media='Google'
                            label='Google'
                        />
                        <OAuthButton
                            media='Azure'
                            label='Microsoft'
                        />
                        <OAuthButton
                            media='LinkedIn'
                            label='LinkedIn'
                        />
                        <div className='flex w-full min-w-full justify-content-center sm:w-7'>
                            <Button
                                onClick={() => setUseOTP(true)}
                                className='bg-white text-gray-600 border-bluegray-200 w-16rem hover:bg-gray-200'>
                                <img
                                    src={'images/svg/wand.svg'}
                                    height={24}
                                    width={24}
                                    alt={'label'}
                                    className='mr-4 ml-1'
                                />
                                One Time Passcode
                            </Button>
                        </div>
                        {useOTP &&
                            <>
                                <div className='mt-3 flex justify-content-center sm:w-7 md:w-16rem'>
                                    <div className='flex-1 p-inputgroup'>
                                        <InputText
                                            disabled={loginlinkSent}
                                            onKeyUp={(e) => {
                                                e.code === 'Enter' && launchMagic()
                                            }}
                                            onChange={(e) => setData('email', e.target.value)}
                                            value={data.email}
                                            placeholder='email' />
                                        <Button
                                            disabled={processing || !data.email}
                                            loading={processing}
                                            onClick={() => launchMagic()}
                                            icon='pi pi-envelope'
                                            className='border-gray-400 p-button-outlined' />
                                    </div>
                                </div>
                            </>
                        }
                        {loginlinkSent &&
                            <>
                                <div className='mt-3 flex w-15rem justify-content-center sm:w-7'>
                                    <div className='font-bold col text-green-400'>
                                        <i className='mr-2 text-green-500 pi pi-check-circle'></i>
                                        Passcode sent to *****{loginlinkSent}
                                    </div>
                                </div>
                                {pinSuccess ?
                                    <div className='card bg-white flex justify-content-center'>
                                        <i className='mr-2 text-green-500 text-4xl pi pi-check-circle'></i>
                                    </div> :
                                    <div className='bg-white mt-2 flex justify-content-center'>
                                        {processingPin ? <ProgressSpinner
                                            // className={'ml-2'}
                                            strokeWidth={3}
                                            animationDuration={5}
                                            style={{ height: '2rem', width: '2rem' }}
                                        /> : <div>
                                            <InputOtp
                                                className={'fauna-otp'}
                                                disabled={processingPin}
                                                length={6}
                                                value={token}
                                                onChange={(e) => setToken(e.value)}
                                                integerOnly
                                            />
                                            <small>Enter received passcode</small>
                                        </div>}
                                    </div>}
                            </>
                        }

                        {error &&
                            <div className='mt-1 flex w-18rem justify-content-center sm:w-7'>
                                <div className='font-bold col text-red-600'>
                                    <i className='mr-2 text-red-500 pi pi-lock'></i>
                                    {error}
                                </div>
                            </div>}
                        {/*<div className='my-3 mt-3 flex w-full min-w-full justify-content-center sm:w-7'>
                            <Link href='/auth/welcome'>
                                <Button
                                    className='mb-4 bg-white text-gray-600 border-bluegray-200 w-16rem hover:bg-gray-200'>
                                    <img
                                        src={rocketIcon}
                                        height={24}
                                        width={24}
                                        alt={'label'}
                                        className='mr-4 ml-1'
                                    />
                                    Get started
                                </Button>
                            </Link>
                        </div>*/}
                        <div
                            className='flex mt-4 w-full min-w-full justify-content-center text-500'
                            style={{
                                fontSize: '0.6rem',
                                marginBottom: '-1rem',
                            }}>&copy;<span className={'mr-2'}>{new Date().getFullYear()}</span>
                            &#x2022; <span className={'mx-2'}>All rights reserved</span>
                            &#x2022; <a
                                className={'mx-2 text-500'}
                                href={'https://faunaphotonics.com/terms-and-conditions'}
                                target={'legal'}>Terms</a>
                            &#x2022; <a
                                className={'mx-2 text-500'}
                                href={'https://faunaphotonics.com/privacy-and-cookies-policy'}
                                target={'legal'}>Privacy</a>
                        </div>
                    </div>
                    {/*<div className='flex field col-10 justify-content-between'>
                        <Link href='/'>
                            <Button
                                title={'back to login page'}
                                className={'border-0'}
                                outlined
                                severity={'secondary'}
                                icon={'fas fa-arrow-left text-gray-300 hover:text-gray-700'}
                            />
                        </Link>
                    </div>*/}
                </div>
            </div>

        </GuestLayout>
    )
}
